/* eslint-disable import/prefer-default-export */
import { makeEl } from './utils/makeEl';

const labels = {
  description: `You don't have access to this application. Please contact your administrator for access.`,
  homeLabel: 'Back to Home',
  homeUrl: 'Learn more',
  learnLabel: 'Learn more',
  learnUrl: 'Learn more',
  logoutLabel: 'Logout',
  logoutUrl: 'Logout',
  support: 'Need help? ',
  supportLabel: 'Contact support',
  supportUrl: 'https://sovos.com/support/',
};

export function unauthorizedPage(el) {
  const productIcon = el.dataset.mosaicUnauthProductIcon;
  const product = el.dataset.mosaicUnauthProduct;
  const description = el.dataset.mosaicUnauthDescription ?? labels.description;
  const learnLabel = el.dataset.mosaicUnauthLearnLabel ?? labels.learnLabel;
  const learnUrl = el.dataset.mosaicUnauthLearnUrl ?? labels.learnUrl;

  const homeLabel = el.dataset.mosaicUnauthHomeLabel ?? labels.homeLabel;
  const homeUrl = el.dataset.mosaicUnauthHomeUrl ?? labels.homeUrl;

  const logoutLabel = el.dataset.mosaicUnauthLogoutLabel ?? labels.logoutLabel;
  const logoutUrl = el.dataset.mosaicUnauthLogoutUrl ?? labels.logoutUrl;
  const supportUrl = el.dataset.mosaicUnauthSupportUrl ?? labels.supportUrl;
  const supportLabel =
    el.dataset.mosaicUnauthSupportLabel ?? labels.supportLabel;

  // .container
  const container = makeEl({ className: 'unauthorized-container' });
  el.append(container);

  // logo
  const unauthLogo = makeEl({
    type: 'div',
    className: 'sovos-unauthorized-page__logo',
  });
  if (productIcon) {
    unauthLogo.style.backgroundImage = `url(${productIcon})`;
  }
  container.append(unauthLogo);

  // Product Name
  if (product) {
    const productName = makeEl({
      type: 'h1',
      className: 'unauthorized-page__product',
      text: product,
    });
    container.append(productName);
  }

  // Description
  const descriptionP = makeEl({
    type: 'p',
    className: 'unauthorized-page__description',
    text: description,
  });
  const learnLink = makeEl({
    type: 'a',
    className: '',
    text: learnLabel,
  });
  learnLink.href = learnUrl;
  descriptionP.append(learnLink);
  container.append(descriptionP);

  const buttonSection = makeEl({
    type: 'div',
    className: 'unauthorized-page__buttonSection',
    text: ' or ',
  });
  container.append(buttonSection);

  // links
  const homeButton = makeEl({
    type: 'a',
    className: 'btn btn-text btn-lg',
    text: homeLabel,
  });
  homeButton.href = homeUrl;
  const homeIcon = makeEl({
    type: 'span',
    text: 'home',
    className: 'material-icons',
  });
  homeButton.prepend(homeIcon);
  buttonSection.prepend(homeButton);

  const logoutButton = makeEl({
    type: 'a',
    className: 'btn btn-text btn-lg',
    text: logoutLabel,
  });
  const logoutIcon = makeEl({
    type: 'span',
    text: 'logout',
    className: 'material-icons',
  });
  logoutButton.prepend(logoutIcon);
  logoutButton.href = logoutUrl;
  buttonSection.append(logoutButton);

  // Support
  const supportP = makeEl({ type: 'p', text: labels.support });
  const supportLink = makeEl({ type: 'a', text: supportLabel });
  supportLink.href = supportUrl;
  supportP.append(supportLink);
  container.append(supportP);
}

const unauthorizedPageEl = document.querySelector('.sovos-unauthorized-page');

if (unauthorizedPageEl) {
  unauthorizedPage(unauthorizedPageEl);
}
