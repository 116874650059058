import { makeEl } from './utils/makeEl';

const getErrorHeading = (code = 404) => {
  const errorHeading = makeEl({ type: 'h2', className: 'error-code' });

  const errorHeadingBefore = makeEl({
    type: 'span',
    className: 'before',
    text: code,
  });

  const errorHeadingAfter = makeEl({
    type: 'span',
    className: 'after',
    text: code,
  });

  errorHeadingAfter.setAttribute('aria-hidden', true);
  errorHeading.append(errorHeadingBefore, errorHeadingAfter);

  return errorHeading;
};

export default getErrorHeading;
