/* eslint-disable import/prefer-default-export */
import { addDrawer, SELECTOR_DRAWER, SELECTOR_TOGGLE } from './drawer';
import { addAllFilledInputs } from './filledInput';
import { addAllFilledSelects } from './filledSelect';
import { addAllSelects } from './select';
import { addSelectArrow } from './selectArrow';

const target = document.querySelector('.content-container');

export const contentObserver = new MutationObserver((mutations) => {
  mutations.forEach((mutation) => {
    if (mutation.type === 'childList') {
      const drawer = document.querySelector(SELECTOR_DRAWER);
      if (drawer) {
        const triggers = document.querySelectorAll(SELECTOR_TOGGLE);
        const drawerInstance = addDrawer(drawer);

        triggers.forEach((trigger) => {
          trigger.addEventListener('click', () => {
            drawerInstance.toggle();
          });
        });
      }

      addAllFilledInputs();
      addAllFilledSelects();
      addAllSelects();
      addSelectArrow();
    }
  });
});

const config = {
  attributes: true,
  childList: true,
  characterData: true,
  subtree: true,
};

if (target) {
  contentObserver.observe(target, config);
}
